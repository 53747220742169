import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { LoggerService } from "../logger.service";
import { ToasterService } from "../toaster.service";
import { AuthService } from "src/app/modules/auth";

const httpOptions: IRequestOptions = {
  headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }),
  withCredentials: false,
};

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: "body";
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: "root",
})
export class AppHttpClient {
  public constructor(
    private httpClient: HttpClient,
    private httpLog: LoggerService,
    private toasterService: ToasterService,
    private authService: AuthService
  ) {}

  public get<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.httpRequest<T>("GET", url, null, options);
  }

  public post<T>(
    url: string,
    params: object,
    options?: IRequestOptions
  ): Observable<T> {
    return this.httpRequest<T>("POST", url, params, options);
  }

  public put<T>(
    url: string,
    params: object,
    options?: IRequestOptions
  ): Observable<T> {
    return this.httpRequest<T>("PUT", url, params, options);
  }

  public delete<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.httpRequest<T>("DELETE", url, null, options);
  }

  private httpRequest<T>(
    method: string,
    url: string,
    body?: object | null,
    options?: IRequestOptions
  ): Observable<T> {
    const startTime = new Date().getTime();
    options = options ?? httpOptions;

    const logMethod = method.toUpperCase();
    this.httpLog.silly(
      `HTTP Request ${logMethod}`,
      url,
      body ? `Params - ${JSON.stringify(body)}` : ""
    );

    const httpCall =
      method === "GET"
        ? this.httpClient.get<T>(url, options)
        : method === "POST"
        ? this.httpClient.post<T>(url, body, options)
        : method === "PUT"
        ? this.httpClient.put<T>(url, body, options)
        : this.httpClient.delete<T>(url, options);

    return httpCall.pipe(
      tap((res) =>
        this.httpLog.silly(
          `HTTP Response ${logMethod}`,
          url,
          `Result - ${JSON.stringify(res)}`
        )
      ),
      catchError((err) => this.handleError(err, method, url, body)),
      map((res) => this.mapResponse(res)),
      finalize(() => {
        const finishTime = new Date().getTime();
        const timeTaken = finishTime - startTime;
        this.httpLog.silly(
          `Response time for ${logMethod} ${url} -`,
          `${timeTaken} ms`
        );
      })
    );
  }

  private mapResponse(res: any): any {
    if (res) {
      if (res.success) {
        return res.data;
      } else {
        let msg = res.message || "An error occurred.";
        if (res.codes === 401) {
          this.authService.logout();
        } else if (res.codes === 0) {
          msg = "Internet is not connected, please try again.";
        }
        this.toasterService.showErrorToast(msg);
        throw msg;
      }
    } else {
      const errorMsg = "Something went wrong";
      this.toasterService.showErrorToast(errorMsg);
      throw errorMsg;
    }
  }

  private handleError(
    err: any,
    method: string,
    url: string,
    body?: object | null
  ): Observable<never> {
    const errorMessage =
      err.status === 0
        ? "Network error: Please check your internet connection or CORS settings."
        : err.error?.message ||
          `Server error (${err.status}): ${err.statusText || "Unknown error"}`;

    this.httpLog.error(
      `Error Response HTTP ${method}`,
      url,
      body ? `Params - ${JSON.stringify(body)}` : "",
      `Error Result - ${JSON.stringify(err)}`
    );
    this.toasterService.showErrorToast(errorMessage);

    return throwError(errorMessage);
  }
}
