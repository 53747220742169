import { AuthModel } from "./auth.model";
import { AddressModel } from "./address.model";
import { SocialNetworksModel } from "./social-networks.model";
import { Role } from "src/app/core/app.constants";

export class UserModel extends AuthModel {
  id: number;
  username: string;
  password: string;
  email: string;
  pic: string;
  role: Role;
  mobile: string;
  address?: AddressModel;
  firstName: string;
  lastName: string;
  roles: number[];

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || "";
    this.password = user.password || "";
    this.firstName = user.firstName || "";
    this.lastName = user.lastName;
    this.email = user.email || "";
    this.pic = user.pic || "./assets/media/users/default.jpg";
    this.role = user.role;
    this.mobile = user.mobile || "";
    this.address = user.address;
  }
}
