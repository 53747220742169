export class AuthModel {
  token: string;
  refreshToken: string;
  expiresIn: Date;
  accessToken: string;

  setAuth(auth: any) {
    this.token = auth.token;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }
}

export interface IStageUserModel {
  firstName: string;
  lastName: string;
  email?: string;
  mobileNumber?: string;
  mobileVerificationToken?: string;
  emailVerificationToken?: string;
  password?: string;
  isEmailVerified?: boolean;
  isMobileVerified?: boolean;
}

export interface IStageResponseModel {
  existingUser?: boolean;
  stageUserId?: number;
}
