import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function AppTranslateLoaderFactory(http: HttpClient) {
    return new AppTranslateLoader(http);
}

@Injectable({
    providedIn: 'root',
})
export class AppTranslateLoader extends TranslateLoader {

    alias: any;
    constructor(private http: HttpClient) {
        super();
    }

    getTranslation(lang: string): Observable<any> {
        return this.http.get(`/assets/i18n/${lang}.json`).pipe(
            catchError(error => of({})) // Handle errors if API fails
        );

    }
}